<template>
  <div class="row">
    <div class="col-md-12">
      <card class="stacked-form">
        <form @submit.prevent="submit">
          <!-- form -->
          <div class="row">
            <div class="col-12 mt-4">
              <label>Imagem</label>
              <div class="upload-container">
                <div v-if="form.photo_url !== '' && form.photo_url !== null" class="upload-preview" :style="'background: url(' + form.photo_url + ')'">
                  <div class="remove-file" @click="form.photo = ''">
                    <p><i class="fa fa-times"></i></p>
                    <p class="pl-2">Remover</p>
                  </div>
                </div>
                <div v-else-if="form.photo !== '' && form.photo !== null" class="upload-preview" :style="'background: url(' + form.photo + ')'">
                  <div class="remove-file" @click="form.photo = ''">
                    <p><i class="fa fa-times"></i></p>
                    <p class="pl-2">Remover</p>
                  </div>
                </div>
                <div class="add-file">
                  <label for="image" v-if="!(form.photo !== '' && form.photo !== null)">
                    Selecionar
                    <span class="tim-icons icon-upload ml-2"></span>
                  </label>
                  <label for="image" v-if="form.photo !== '' && form.photo !== null">
                    Alterar
                    <span class="tim-icons icon-pencil ml-2"></span>
                  </label>
                  <input @change.prevent="addLogo" type="file" id="image" accept="image/png, image/jpeg, image/jpg" />
                </div>
              </div>
            </div>

            <div class="col-6">
              <label for="category_id">Categoria</label>
              <select id="category_id" v-model="form.category_id" class="form-control custom-select">
                <option v-for="category in categories" :key="category.id" :value="category.id">
                  {{ category.title }}
                </option>
              </select>
            </div>

            <div class="col-6">
              <label for="unity_id">Unidade de Medida</label>
              <select id="unity_id" v-model="form.unity_id" class="form-control custom-select">
                <option v-for="unity in unities" :key="unity.id" :value="unity.id">
                  {{ unity.unity }}
                </option>
              </select>
            </div>

            <div class="col-12">
              <base-input label="Nome" v-model="form.name"></base-input>
            </div>

            <div class="col-12">
              <label for="description_storage">Como armazenar</label>
              <textarea id="description_storage" v-model="form.description_storage" class="form-control"></textarea>
            </div>

            <div class="col-12">
              <label for="description_discard">Como descartar</label>
              <textarea id="description_discard" v-model="form.description_discard" class="form-control"></textarea>
            </div>

            <div class="col-12">
              <label for="recyclable">
                São recicláveis (separar items por ponto e virgula ;)
              </label>
              <textarea id="recyclable" v-model="form.recyclable" class="form-control"></textarea>
            </div>

            <div class="col-12">
              <label for="not_recyclable">
                Não são recicláveis (separar items por ponto e virgula ;)
              </label>
              <textarea id="not_recyclable" v-model="form.not_recyclable" class="form-control"></textarea>
            </div>

            <div class="col-12">
              <label class="mr-3 mb-0">Status:</label>
              <el-radio-group v-model="form.status">
                <el-radio class="mb-0" :label="1">Ativo</el-radio>
                <el-radio class="mb-0" :label="0">Inativo</el-radio>
              </el-radio-group>
            </div>

            <!-- botoes -->
            <div class="col-12 mt-3">
              <base-button @click.prevent.stop="goBack()" type="danger">Cancelar</base-button>
              <base-button native-type="submit" type="success" class="ml-3">Salvar</base-button>
            </div>
          </div>
        </form>
      </card>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import swal from 'sweetalert2'
import vSelect from 'vue-select'
Vue.component('v-select', vSelect)
export default {
  data: () => ({
    form: {
      status: 0,
      photo_url: '',
      photo: null
    },
    categories: [],
    unities: []
  }),
  methods: {
    async getData () {
      const id = this.$route.params.id || false
      if (id) {
        const data = await this.$http.get('items/' + id)
        this.form = { ...data.data }
      }
    },
    async getCategories () {
      const url = 'categories?type=MATERIAIS'
      const { data } = await this.$http.get(url)
      this.categories = data.data
      this.categories.filter(item => {
        return item.status === 1
      })
    },
    async getUnities () {
      const url = 'unities'
      const { data } = await this.$http.get(url)
      this.unities = data.data
    },
    addLogo (e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      const vue = this
      const file = files[0]
      const reader = new FileReader()

      reader.onload = e => {
        vue.form.photo = e.target.result
      }
      reader.readAsDataURL(file)
    },
    async submit () {
      if (await this.validate()) {
        const id = this.$route.params.id || false
        let response
        try {
          if (id) {
            response = await this.$http.put(`items/${id}`, this.form)
          } else {
            response = await this.$http.post('items', this.form)
          }
        } catch (e) {
          await swal.fire({
            title: 'Erro!',
            text: 'Ocorreu um erro, tente novamente mais tarde.',
            denyButtonText: 'Ok',
            showDenyButton: true,
            showConfirmButton: false
          })
        }
        if (response.status === 200) {
          await swal.fire({
            title: 'Salvo!',
            text: `"${this.form.name}" salvo com sucesso.`,
            showConfirmButton: false,
            timer: 2000
          })
          this.goBack()
        } else {
          await swal.fire({
            title: 'Erro!',
            text: 'Ocorreu um erro, tente novamente mais tarde.',
            denyButtonText: 'Ok',
            showDenyButton: true,
            showConfirmButton: false
          })
        }
      } else {
        await swal.fire({
          title: 'Erro!',
          text: 'Por favor, preencha todos os campos obrigatórios.',
          denyButtonText: 'Ok',
          showDenyButton: true,
          showConfirmButton: false
        })
      }
    },
    goBack () {
      window.history.go(-1)
    },
    async validate () {
      const form = this.form
      if (!form.category_id) return false
      if (!form.unity_id) return false
      if (!form.name) return false
      if (!form.description_storage) return false
      if (!form.description_discard) return false
      if (!form.photo) return false
      return true
    }
  },
  async mounted () {
    await this.getData()
    await this.getCategories()
    await this.getUnities()
  }
}
</script>
<style lang="scss" scoped>
.avatar-large-content {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  border: 1px solid #ccc;
}

.avatar-large-content img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
}

table {
  border-radius: 0.75rem;
  overflow: hidden;
}

table thead tr {
  border-top: 2px transparent solid;
}
.upload-preview,
.add-file {
  width: 8rem !important;
  height: 7rem !important;

  label,
  p {
    font-size: 1.05rem !important;
  }
}
</style>
